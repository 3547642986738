// components/Notification.js
import { useEffect, useState } from 'react';
import { parseCookies } from 'nookies';
import { setCookiesWithNookies } from 'utils/set-cookies-with-nookies';
import useNotifications from 'queries/useNotifications';
import { NotificationIcon } from 'components/SvgIcons/NotificationIcon';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import useRtl from 'hooks/useRtl';
import useFcmToken from 'queries/useFcmToken';
import firebaseApp from 'utils/firebase/firebase';
import { getMessaging, onMessage } from 'firebase/messaging';

const NotificationCard = dynamic(() => import('./NotificationCard'), { ssr: false });

const Notification = () => {
  const { notificationPermissionStatus, fcmToken } = useFcmToken();
  const { data: notifications, isLoading, refetch } = useNotifications();

  if (typeof window !== 'undefined' && 'serviceWorker' in navigator && notificationPermissionStatus && fcmToken) {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, (payload) => {
      refetch();
      // Handle the received push notification while the app is in the foreground
      // You can display a notification or update the UI based on the payload
    });

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'REFETCH_NOTIFICATIONS') {
        refetch();
      }
    });
  }

  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(
    getUnreadNotificationsCount(notifications?.total_count),
  );
  const isRtl = useRtl();

  useEffect(() => {
    setUnreadNotificationsCount(getUnreadNotificationsCount(notifications?.total_count));
  }, [isLoading, notifications]);

  return (
    <div className='relative items-center flex w-[50px]'>
      <button
        aria-label='notification'
        className={unreadNotificationsCount > 0 ? 'bell-icon' : ''}
        onClick={(e) => {
          e.stopPropagation();
          setUnreadNotificationsCount(0);
          setShowNotifications((prev) => !prev);
          setCookiesWithNookies('notificationsHadReadCount', String(notifications?.total_count));
        }}
      >
        <NotificationIcon />
        {unreadNotificationsCount > 0 && (
          <span
            className={clsx(
              'absolute top-0 rtl:lg:right-[15px] rtl:md:right-[15px] rtl:right-[20px] px-[3px] right-[10px] text-green-800 bg-white w-auto h-auto border rounded-full border-green-900 text-[8px]',
              {
                'md:translate-x-[-10px]': isRtl,
              },
            )}
          >
            {unreadNotificationsCount}
          </span>
        )}
      </button>
      {showNotifications && (
        <NotificationCard setShowNotifications={setShowNotifications} notifications={notifications} />
      )}
    </div>
  );
};

const getUnreadNotificationsCount = (totalNotificationsCount) => {
  if (!totalNotificationsCount) {
    return 0;
  }
  return totalNotificationsCount - (Number(parseCookies().notificationsHadReadCount) || 0);
};

export default Notification;
