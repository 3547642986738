import PharmacyImage from 'components/PharmacyImage';
import Notification from 'components/Header/Notification';
import Link from 'next/link';
import useRtl from 'hooks/useRtl';

export const MobileHeader = () => {
  const isRtl = useRtl();
  return (
    <span className='lg:hidden grid grid-cols-3'>
      <span></span>
      <div className='flex items-center justify-center mb-4 mt-4'>
        <Link href={'/'} prefetch={false}>
          <PharmacyImage
            className='cursor-pointer'
            width={40}
            minHeight={40}
            height={40}
            objectFit='contain'
            src='/local/android-chrome-512x512.png'
            localImage
            alt='AR'
          />
        </Link>
      </div>
      <div className='relative'>
        <span className={`absolute  ${isRtl ? 'left-0' : 'right-0'} pt-6 max-w-fit`}>
          <Notification />
        </span>
      </div>
    </span>
  );
};
