import { get, post } from 'models/base';
import { Notification } from '../types/notification/notification.type';

export const notifications = async (req?: any): Promise<Notification> => {
  const response = await get(`/customer/notifications`, req);
  return response.data;
};

export const fetchNotificationsWithToken = async (token: string): Promise<Notification> => {
  try {
    const response = await post('/push-notification-token', { token });
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
};
