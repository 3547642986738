import { eventConfig } from 'lib/constants';
import AdjustEvent from '../../../components/AdjustComponent';
import { insiderPush } from '../insiderPush';
import { dataLayerPush } from './datalayerPush';
import {
  handleFbApiConversionAddToCart,
  handleFbApiConversionPurchase,
} from 'utils/facebook-conversions/events-handlers';
import {
  handleTqqApiConversionAddToCart,
  handleTqqApiConversionPurchase,
} from 'utils/tiktok-conversions/events-handlers';
import {
  handleSnpApiConversionAddToCart,
  handleSnpApiConversionPurchase,
} from 'utils/snpchat-conversions/events-handlers';

export interface ItemRequiredToSetEventResponse {
  entity_id?: number;
  sku: string;
  status?: string;
  name: string;
  category_name?: string;
  type?: string;
  item_brand?: string;
  qty?: number;
  qty_ordered?: number;
  product?: {
    name?: any;
    sku?: any;
    entity_id?: number;
    attributes?: {
      brand?: {
        value?: {
          label?: string;
        };
      };
    };
    price?: {
      with_tax_rounded?: number;
    };
    category: { name: string }[];
    salable_stock: number;
  };
  price?: any;
  [key: string]: any;
}

function eventItemFactory(data: ItemRequiredToSetEventResponse[]): {
  item_id: number;
  item_sku: string;
  item_name: string;
  item_category: any;
  item_variant: string;
  item_brand: any;
  price: number;
  index: number;
  quantity: number;
}[] {
  return data?.map((item, index) => {
    return {
      item_id: item.entity_id ?? item.item_id,
      item_sku: item.sku,
      item_name: item.name,
      item_category:
        item.category_name ??
        item?.product?.category[0]?.name ??
        (item?.category && item?.category[item?.category?.length - 1]?.name) ??
        '',
      item_variant: item.type ?? 'simple',
      item_brand: item?.item_brand ?? item.attributes?.brand?.value?.label ?? '',
      price: item?.product?.price?.with_tax_rounded ?? item.price?.with_tax_rounded ?? item.price,
      index,
      quantity: item?.qty ?? item?.qty_ordered ?? item?.product_pdp_quantity ?? 1, // item.qty is from cart, item.qty_ordered is from order. fallback to 1 is for product listing.
    };
  });
}

// TODO: Remove this and get total from the cart and order always since we have tax, shipping and discount etc.
function sumData(data: ItemRequiredToSetEventResponse[]): number {
  return data?.reduce((prev, current) => prev + current.price * current.quantity, 0);
}

export function viewItemListEvent(data: ItemRequiredToSetEventResponse[], item_list_name: string) {
  if (!data) return;

  const items = eventItemFactory(data);
  if (items && items.length > 0) {
    pushAllEvents(eventConfig.viewItemListEvent, {
      items,
      item_list_name,
      item_list_id: item_list_name?.toLowerCase().replaceAll(' ', '_'),
    });
  }
}

export function viewCartEvent(data: ItemRequiredToSetEventResponse[], value: number) {
  if (!data) return;

  const items = eventItemFactory(data);
  if (items && items.length > 0) {
    pushAllEvents(eventConfig.view_cart, {
      items,
      currency: 'SAR',
      value,
    });
  }
}

export const selectContentEvent = (data: ItemRequiredToSetEventResponse[], item_list_name?: string) => {
  if (!data) return;

  const items = eventItemFactory(data);

  pushAllEvents(eventConfig.selectContentEvent, {
    items,
    item_list_name,
    item_list_id: item_list_name && item_list_name?.toLowerCase()?.replaceAll(' ', '_'),
  });
};

export const viewItemEvent = (item: any, currency: string, value: number) => {
  const items = eventItemFactory([item]);
  pushAllEvents(eventConfig.viewItemEvent, { items, currency: currency, value: value });
};

export const addToCartEvent = async (data: ItemRequiredToSetEventResponse) => {
  if (!data) return;

  const items = eventItemFactory([data]);
  const totalValue = data?.product?.price?.with_tax_rounded ?? data.price?.with_tax_rounded ?? data.price;

  if (typeof window !== 'undefined') {
    const commonData = {
      content_name: data?.name,
      content_ids: [data?.sku],
      content_type: 'product',
      quantity: data?.product_pdp_quantity ?? 1,
      value: totalValue * (data?.product_pdp_quantity ?? 1),
      currency: 'SAR',
    };

    // Facebook Pixel
    window.fbq('track', 'AddToCart', {
      ...commonData,
    });

    // TikTok Pixel
    window.ttq?.track('AddToCart', {
      ...commonData,
    });

    // Snap Pixel
    window.snaptr('track', 'ADD_CART', {
      ...commonData,
    });

    // Twitter Pixel
    window.twq('track', 'AddToCart', {
      content_name: data?.name,
      content_ids: [data?.sku],
      content_type: 'product',
      value: totalValue * (data?.product_pdp_quantity ?? 1),
      currency: 'SAR',
    });

    // Check if SKU is available, which is typically for Merchandising customers
    if (data?.sku) {
      window._uxa.push([
        'ec:cart:add',
        {
          sku: data?.sku, // SKU of the product being added
          merchant: 'merchantName', // Merchant name, optional
        },
      ]);
    } else {
      // For DXA Web only, SKU can be omitted
      window._uxa.push(['trackPageEvent', 'addToCart']);
      window._uxa.push([
        'ec:cart:add',
        {
          merchant: 'merchantName', // Merchant name, required for DXA Web
        },
      ]);
    }
  }

  data.price = totalValue * (data?.product_pdp_quantity ?? 1);

  await handleFbApiConversionAddToCart(data);
  await handleTqqApiConversionAddToCart(data);
  await handleSnpApiConversionAddToCart(data);

  pushAllEvents(
    eventConfig.addToCartEvent,
    {
      items,
      currency: 'SAR',
      value: totalValue * data?.product_pdp_quantity,
    },
    data?.product?.price?.with_tax_rounded ?? data.price?.with_tax_rounded ?? data.price,
  );
};

export const removeFromCartEvent = (data: ItemRequiredToSetEventResponse) => {
  // const item = {
  //   item_id: data.entity_id,
  //   item_name: data.name,
  //   item_category: data.category_name ?? data?.category?.find((item: any) => item.name)?.name,
  //   item_variant: data.type,
  //   item_brand: data?.item_brand ?? data.attributes?.brand?.value?.label,
  //   price: data?.product?.price?.with_tax_rounded ?? data.price?.with_tax_rounded ?? data.price,
  //   currency: 'SAR',
  //   sku: data.sku,
  //   stock_status: data.status,
  // };

  if (!data) return;
  const items = eventItemFactory([data]);

  pushAllEvents(
    eventConfig.removeFromCartEvent,
    {
      items,
      currency: 'SAR',
      value: data?.product?.price?.with_tax_rounded ?? data.price?.with_tax_rounded ?? data.price,
    },
    data?.product?.price?.with_tax_rounded ?? data.price?.with_tax_rounded ?? data.price,
  );
};

export const beginCheckoutEvent = (data: ItemRequiredToSetEventResponse[], totalValue: number, coupon?: string) => {
  if (!data) return;

  const items = eventItemFactory(data);

  pushAllEvents(
    eventConfig.beginCheckoutEvent,
    {
      items,
      value: totalValue,
      currency: 'SAR',
      coupon,
    },
    totalValue,
  );
};

export const purchaseEvent = async ({
  data,
  value,
  tax,
  shipping,
  payment,
  coupon,
  transaction_id,
  is_prescription,
  discount,
}: {
  data: ItemRequiredToSetEventResponse[];
  value: number;
  tax: number;
  shipping: number;
  payment: number;
  coupon: string;
  transaction_id: string;
  is_prescription: number;
  discount: number;
}) => {
  if (!data) return;

  const items = eventItemFactory(data);

  const fullData = {
    items,
    value: +Number(value).toFixed(2),
    tax: +Number(tax).toFixed(2),
    payment: +(payment ? Number(payment).toFixed(2) : 0.0),
    shipping: +Number(shipping).toFixed(2),
    currency: 'SAR',
    coupon,
    is_prescription,
    discount,
    transaction_id: `${transaction_id}`,
  };

  if (typeof window !== 'undefined') {
    const commonData = {
      content_ids: [transaction_id],
      content_name: 'Product Name',
      content_type: 'Purchase',
      value: +Number(value).toFixed(2),
      currency: 'SAR',
      num_items: +Number(shipping).toFixed(2),
    };

    window?.fbq('track', 'Purchase', {
      ...commonData,
    });

    window?.snaptr('track', 'Purchase', {
      ...commonData,
    });

    window?.twq('track', 'Purchase', {
      ...commonData,
    });

    window?.ttq?.track('Purchase', {
      ...commonData,
      event_id: transaction_id,
    });
  }

  await handleFbApiConversionPurchase(fullData);
  await handleTqqApiConversionPurchase(fullData);
  await handleSnpApiConversionPurchase(fullData);

  pushAllEvents(eventConfig.purchaseEvent, fullData, value);
};

export const addPaymentInfoEvent = (
  data: ItemRequiredToSetEventResponse[],
  payment_type?: string,
  coupon?: string,
  currency?: string,
  value?: number,
) => {
  if (!data) return;

  const items = eventItemFactory(data);

  pushAllEvents(eventConfig.addPaymentInfoEvent, { items, payment_type, coupon, currency, value }, sumData(data));
};

export const addShippingInfoEvent = (
  data: ItemRequiredToSetEventResponse[],
  shipping_tier?: string,
  coupon?: string,
  currency?: string,
  value?: number,
) => {
  if (!data) return;

  const items = eventItemFactory(data);

  pushAllEvents(eventConfig.addShippingInfoEvent, { items, shipping_tier, coupon, currency, value }, sumData(data));
};

const pushAllEvents = (
  {
    adjustName,
    defaultName,
  }: {
    defaultName: string;
    adjustName: string;
  },
  defaultData: any,
  adjustData = 0,
) => {
  const defaultEventObject = { eventName: defaultName, data: defaultData };
  console.log({ event: defaultEventObject });

  dataLayerPush(defaultEventObject);

  insiderPush(defaultEventObject);
  AdjustEvent(adjustName, adjustData, 'SAR');
};
