// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDKxBe84DkUlMtiGXP7eQv7Nsje9wt05CA',
  authDomain: 'alhabib-pharmacy-staging.firebaseapp.com',
  projectId: 'alhabib-pharmacy-staging',
  storageBucket: 'alhabib-pharmacy-staging.firebasestorage.app',
  messagingSenderId: '172321440983',
  appId: '1:172321440983:web:52dd0030825cfc3ad59c92',
  measurementId: 'G-7DEMG9VM9E',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
