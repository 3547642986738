import { NextApiRequest, NextApiResponse } from 'next';
import { LokaliseApi } from '@lokalise/node-api';
import fs from 'fs';
import path from 'path';

const myApi = process.env.LOKALISE_API_KEY ?? '5282a50669d35ce6eb6b764906b0f1b4e8b2f20a';
const project_id = process.env.LOKALISE_PROJECT_ID ?? '90257586636b6a058ef215.32602055';
const lokaliseApi = new LokaliseApi({ apiKey: myApi });

const localesPath = path.resolve('./public/locales');

// Utility function to introduce a delay
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Synchronize translations from Lokalise and cache locally
export const syncTranslations = async () => {
  try {
    const keys: { [key: string]: string } = {};
    const translations: { [locale: string]: { [key: string]: string } } = {
      en: {},
      ar: {},
    };

    let page = 0;
    let resKeys, resTranslations;

    // Fetch keys from Lokalise with delay between paginated requests
    do {
      page++;
      await delay(1000); // Wait 200ms between requests to avoid rate limits
      resKeys = await lokaliseApi.keys().list({ project_id, page, limit: 5000 });
      resKeys.items.forEach((key) => {
        keys[key.key_id] = key.key_name.web;
      });
    } while (resKeys?.items.length === 5000);

    page = 0;

    // Fetch translations from Lokalise with delay between paginated requests
    do {
      page++;
      await delay(200); // Wait 200ms between requests to avoid rate limits
      resTranslations = await lokaliseApi.translations().list({ project_id, page, limit: 5000 });
      resTranslations.items.forEach((key) => {
        const keyName = keys[key.key_id]?.replace('{', '{{')?.replace('}', '}}');
        if (key.translation) {
          translations[key.language_iso][keyName] = key.translation.replace('{', '{{').replace('}', '}}');
        } else {
          // Handle case where translation might be missing
          translations[key.language_iso][keyName] = keyName;
        }
      });
    } while (resTranslations?.items.length === 5000);

    // Write the translations to JSON files
    await fs.writeFileSync(`${localesPath}/en/common.json`, JSON.stringify(translations.en, null, 2));
    await fs.writeFileSync(`${localesPath}/ar/common.json`, JSON.stringify(translations.ar, null, 2));

    // Return the updated content of the JSON files
    const updatedEnTranslations = await fs.readFileSync(`${localesPath}/en/common.json`, 'utf-8');
    const updatedArTranslations = await fs.readFileSync(`${localesPath}/ar/common.json`, 'utf-8');

    return {
      en: JSON.parse(updatedEnTranslations),
      ar: JSON.parse(updatedArTranslations),
    };
  } catch (error) {
    console.error('Error synchronizing translations:', error);
    throw error;
  }
};

export const config = {
  api: {
    externalResolver: true,
  },
};

export default async function handler(req: NextApiRequest, res: NextApiResponse) {
  try {
    const updatedTranslations = await syncTranslations();
    res.status(200).json({
      message: 'Translations updated successfully',
      updatedTranslations,
    });
  } catch (error) {
    console.error('Error updating translations:', error);
    res.status(500).json({ error: 'Failed to update translations' });
  }
}
